import mitt, {Emitter} from "mitt";
import {Modals} from "@packages/stores/modals";
import {ProductBrand, ProductCategory} from "@packages/contracts/firm-product";

export type Notify = {
    title: string;
    text: string;
    position?: "top-left" | "top-center" | "top-right"
    duration?: number
};

export type Events = {
    "show:modal": Modals
    "close:modal": string
    "close:modal:all": void
    "closed:modal": string | number
    "refresh:calendar": void
    "refresh:table": void
    "notify:received": Notify
    "client:created": any
    "bundle:saved": any
    //refreshes the events used by the appointment group modal (i.e.: show-events.vue). Must emit the appointment group's
    //code (the shared "appointments.code" attribute for the group's appointments), i.e.: emit("refresh:appointment-group", 'Ghd1asD213')
    "refresh:appointment-group": string
    //emitted by the "auth" store after the user has logged in. you may use the store to access authentication data
    "loggedIn": void
    //emitted by SmsVerificationDialog.vue when the user clicks the "verify" button. the value is the verification code
    //i.e.: "279009"
    "sms_verification_dialog.verify_sms": string
    "sms_verification_dialog.set_error": string | null,
    "bundles-saved": void
    "blacklist-updated": void,
    "product-option-added": { type: 'Category' | 'Brand', payload: ProductBrand | ProductCategory }
};

type EmitterWithOnce = Emitter<Events> & { once: (type: keyof Events, handler: Function) => Function }

const emitter: any = mitt<Events>()

export default function useEmitter(): EmitterWithOnce {
    /**
     * Retain the function returned by this method to drop the handler by Emitter.off()
     * @param type
     * @param handler
     */
    emitter.once = (type: keyof Events, handler: Function): Function => {
        function integratedHandler(...args: any[]) {
            //removes the handler from the registered listeners
            emitter.off(type, integratedHandler)

            //invokes the handler
            handler(...args)
        }

        //registers the integrated handler
        emitter.on(type, integratedHandler)

        //returns the integrated handler so that it can be dropped using Emitter.off().
        return integratedHandler
    }

    return emitter;
}
